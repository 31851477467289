<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("tests.testHeader")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col cols="12" md="6">
                <v-select
                  v-model="editedItem.testParticularGuid"
                  :label="$t('tests.particularName')"
                  :items="testParticular"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.testCode"
                  :rules="[rules.required]"
                  :label="$t('tests.testCode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.testName"
                  :rules="[rules.required]"
                  :label="$t('tests.testName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.shortCode"
                  :label="$t('tests.shortCode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="editedItem.sampleType"
                  :label="$t('tests.sampleType')"
                  :items="sampleTypes"
                  item-text="text"
                  item-value="value"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="editedItem.isActive"
                  :label="$t('tests.isActive')"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.testDescription"
                  :label="$t('tests.testDescription')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch
                  v-model="editedItem.useTemplate"
                  :label="$t('tests.useTemplate')"
                  @change="useTemplateChange"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  :class="editedItem.useTemplate ? '' : 'd-none'"
                  v-model="editedItem.testTemplateGuid"
                  :label="$t('tests.templateName')"
                  :items="testTemplates"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "particularTypes",
    "testTemplates",
    "sampleTypes",
    "testParticular",
  ],
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    useTemplateChange() {
      if (this.editedItem.useTemplate == false) {
        //console.log(this.editedItem.testTemplateGuid);
        this.editedItem.testTemplateGuid = null;
      }
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          this.$axios
            .put(
              "TestHeaderSchema/Update?guid=" + this.editedItem.guid,
              this.editedItem
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        } else {
          this.$axios
            .post("TestHeaderSchema/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
